import { template as template_37aab5076ff34bf5b0bcf62fceb0c9e3 } from "@ember/template-compiler";
const FKControlMenuContainer = template_37aab5076ff34bf5b0bcf62fceb0c9e3(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
