import { template as template_af6ad71dba7b46a6944c6d0184cd8e0f } from "@ember/template-compiler";
const FKText = template_af6ad71dba7b46a6944c6d0184cd8e0f(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
